import React from 'react'

import { siteMetadata } from '../../gatsby-config'
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import { injectIntl, Link, FormattedMessage,FormattedHTMLMessage } from "gatsby-plugin-intl"

import Image1 from 'components/DoubledegreeImages/img1'
import Image2 from 'components/DoubledegreeImages/img2'
import Image3 from 'components/DoubledegreeImages/img3'
import Image4 from 'components/DoubledegreeImages/img4'
import Image5 from 'components/DoubledegreeImages/img5'


const DoubleDegreePage = ({ intl }) => {
  
  return (

    <Layout> 
      <SEO
            site={siteMetadata}
            title="Double Degree"
            description="Graduate with 2 degrees from 2 universities and be a part of 2 alumni networks. Contact us to leverage your study abroad experience with Double Degree program"
            
           />

  <section id="thumbnail">
    <div className="center-cropped positionBottom">
        <img className="positionBottom-2" src={require('../images/doubledegree1.jpeg')} alt="plan.jpeg" />
        <div className="centered">
    <p id="thumbnailText"><em><FormattedMessage id="dd_pursuing" /> </em></p>
    <p id="thumbnailText"><em><FormattedMessage id="dd_consultant" /></em></p>
    </div>
    </div>
    
  </section>

  <section id="whyDoubleDegree" className="centeredText">
  <div className="container m-r-auto m-l-auto">
                <h1><FormattedMessage id="dd_why" /></h1>
                <p style={{marginBottom:'10%'}}><FormattedHTMLMessage id="dd_why_p" /> </p>
  </div>
  </section>    
      
  <section id="ddFeaturesSection">   
    <div className="container">
    

      <div className="row">

            <div className="col-lg-5 col-12 mb-4">
            
                <Image1 alt="doubledegree2.jpeg" />
            
            </div>
            <div className="col-lg-7 col-12">
            <h3><strong><FormattedMessage id="dd_features_1_h" /></strong> </h3>
                <p><FormattedMessage id="dd_features_1_p" /></p>
            </div>
            <div className="col-12">
            <hr className="custom_hr" style={{marginTop:'5%'}}/>
            </div>
            <div className="col-lg-5 col-12 mb-4">
              
                <Image2 alt="doubledegree3.jpeg" />
             
            </div>
            <div className="col-lg-7">
            <h3><strong><FormattedMessage id="dd_features_2_h" /></strong></h3>
                    <p><FormattedMessage id="dd_features_2_p" /> </p>
            </div>
            <div className="col-12">
            <hr className="custom_hr" style={{marginTop:'5%'}}/>
            </div>
            <div className="col-lg-5 col-12 mb-4">
            
                <Image3 alt="doubledegree4.jpeg" />
              
            </div>
            <div className="col-lg-7 col-12">
            <h3><strong><FormattedMessage id="dd_features_3_h" /></strong></h3>
                <p><FormattedMessage id="dd_features_3_p" /> </p>
            </div>
            <div className="col-12">
            <hr className="custom_hr" style={{marginTop:'5%'}}/>
            </div>
            <div className="col-lg-5 col-12 mb-4">
              
                <Image4 alt="doubledegree5.jpeg" />
              
            </div>
            <div className="col-lg-7 col-12">
            <h3><strong><FormattedMessage id="dd_features_4_h" /></strong></h3>
                    <p><FormattedMessage id="dd_features_4_p" /></p>
            </div>
            <div className="col-12">
            <hr className="custom_hr" style={{marginTop:'5%'}}/>
            </div>
            <div className="col-lg-5 col-12 mb-4">
              
                <Image5 alt="doubledegree6.jpeg" />
              
            </div>
            <div className="col-lg-7 col-12">
            <h3><strong><FormattedMessage id="dd_features_5_h" /></strong></h3>
                    <p><FormattedMessage id="dd_features_5_p" /></p>
            </div>
            <div className="col-12">
            <hr className="custom_hr" style={{marginTop:'5%'}}/>
            </div>
          </div>
          </div>
          </section>
        
    </Layout>
  )
}

export default injectIntl(DoubleDegreePage)